<template>
  <div class="delclass">
    <el-dialog
      custom-class="ql-dialog delClassDialog"
      title="删除班级"
      :visible.sync="delClassDialogVisible"
      @close="dialogClose"
      width="500px"
    >
      <!-- 警告标识 -->
      <div class="warning">
        <i class="el-icon-warning" style="color: #e6a23c"></i>
      </div>
      <div class="tips">注意该班级已产生的业务数据会被清除</div>
      <div class="message">{{ message }}</div>
      <div class="dialogFooter">
        <span slot="footer">
          <el-button @click="handleConfirm">删除</el-button>
          <el-button @click="dialogClose">取消</el-button>
        </span>
      </div>
    </el-dialog>
    <messageDialog :messageFlag.sync="messageFlag" @messageClose="messageClose"></messageDialog>
  </div>
</template>
<script>
import { Button, Dialog } from 'element-ui';
import { deleteClass } from '@/api/basic';

import messageDialog from './messageDialog.vue';

export default {
  name: 'DelClassDialog',
  data() {
    return {
      delClassDialogVisible: this.delClassDialogFlag,
      messageFlag: false,
    };
  },
  props: {
    delClassDialogFlag: {
      type: Boolean,
    },
    message: {
      type: String,
    },
    classId: {
      type: String,
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    messageDialog,
  },
  methods: {
    dialogClose() {
      this.$emit('update:delClassDialogFlag', false);
    },
    handleConfirm() {
      // this.$emit('delClassConfirm');
      // this.dialogClose();
      deleteClass({
        classId: this.classId,
      })
        .then((res) => {
          if (res && res.result == 0) {
            this.$emit('delClassConfirm');
            this.dialogClose();
          } else {
            this.messageFlag = true;
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
      //
      // this.messageFlag = true;
    },
    messageClose() {
      this.dialogClose();
    },
  },
  watch: {
    delClassDialogFlag() {
      this.delClassDialogVisible = this.delClassDialogFlag;
    },
  },
};
</script>
<style lang="scss" scoped src="../index.scss"></style>
