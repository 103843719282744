<template>
  <div>
    <el-dialog
      custom-class="ql-dialog studentListDialog"
      :title="tableTitle"
      :visible.sync="dialogTableVisible"
      @close="dialogClose"
      width="896px"
    >
      <div class="addStudent">
        <span>共{{ gridData.length }}人</span>
        <el-button
          :disabled="notAllowOperate"
          type="primary"
          icon="el-icon-plus"
          size="small"
          @click="handleAddStudent"
          class="addStudent-btn"
          >新增学生</el-button
        >
      </div>
      <el-table :data="gridData" max-height="450" width="800" v-loading="loading" border>
        <!-- 新增帐号 -->
        <el-table-column prop="shortUserId" label="帐号" width="125" align="left"></el-table-column>
        <el-table-column prop="userName" label="姓名" width="220" align="left"></el-table-column>
        <el-table-column prop="studentNo" label="学号" align="left" width="220"></el-table-column>
        <!-- <el-table-column prop="innerStudentNo" label="班级内部学号" width="120" align="left"></el-table-column> -->
        <el-table-column prop="status" label="操作" align="left">
          <template slot-scope="scope">
            <el-button :disabled="notAllowOperate" type="text" size="small" @click="handleTransfer(scope.row)"
              >转班</el-button
            >
            <!-- <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button> -->
            <el-button :disabled="notAllowOperate" type="text" size="small" @click="handleDel(scope.row)" del
              >删除</el-button
            >
          </template>
        </el-table-column>
        <span v-if="!gridData.length">暂无数据</span>
      </el-table>
      <span slot="footer">
        <el-button @click="dialogClose" size="small">取消</el-button>
        <el-button type="primary" @click="dialogClose" size="small">确定</el-button>
      </span>
    </el-dialog>

    <!-- 新的弹窗 -->
    <transferClassDialog
      :transferDialogFlag.sync="transferDialogFlag"
      @transferStudent="transferStudentConfirm"
      :gradeList="gradeList"
      :userId="userId"
      :studentName="studentName"
      :classId="classId"
    ></transferClassDialog>
    <addStudentDialog
      :addStudentDialogFlag.sync="addStudentDialogFlag"
      @addStudent="addStudentConfirm"
      :classId="classId"
    ></addStudentDialog>
    <!-- <editInnerNoDialog
      :editDialogFlag.sync="editDialogFlag"
      :classId="classId"
      :userId="userId"
      :studentName="studentName"
      @updateInnerNo="updateInnerNoConfirm"
    ></editInnerNoDialog> -->
  </div>
</template>

<script>
import { getClassUserList, deleteClassUser, updateClassUser, addNewClassUser, changeClassUser } from '@/api/basic';

import { Button, Dialog, Table, TableColumn } from 'element-ui';

import transferClassDialog from './transferClassDialog.vue';
import addStudentDialog from './addStudentDialog.vue';
// import editInnerNoDialog from './editInnerNoDialog.vue';

export default {
  name: 'StudentListDialog',
  props: {
    className: {
      type: String,
      default: '',
    },
    classId: {
      type: String,
    },
    dialogFlag: {
      type: Boolean,
    },
    gradeList: {
      type: Array,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      gridData: [],
      transferDialogFlag: false,
      addStudentDialogFlag: false,
      editDialogFlag: false,
      //对表格的操作
      //要操作的学生学号
      userId: '',
      studentName: '',
      loading: false,
    };
  },
  computed: {
    tableTitle() {
      return this.className + '学生名单';
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  created() {
    if (this.classId) {
      this.init();
    }
  },
  methods: {
    handleDel(val) {
      if (this.notAllowOperate) return;

      let mes = `确定删除该班级中的"${val.userName}"吗？删除后将保留学生在当前班级已产生的业务记录，但不再对业务记录进行统计分析。`;
      this.$msgbox
        .confirm(mes, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
        .then(() => {
          // this.gridData = [];
          this.loading = true;
          deleteClassUser({
            userId: val.userId,
            classId: this.classId,
          }).then((res) => {
            if (res.result == 0) {
              this.$message({
                showClose: true,
                message: '删除学生成功',
                type: 'success',
              });
              this.init();
            }
          });
          //然后判断是否成功init()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作',
          });
        });
    },
    dialogClose() {
      this.$emit('update:dialogFlag', false);
    },

    //转班
    handleTransfer(val) {
      if (this.notAllowOperate) return;

      this.userId = val.userId;
      this.studentName = val.userName;
      this.transferDialogFlag = true;
    },
    handleEdit(val) {
      if (this.notAllowOperate) return;

      this.userId = val.userId;
      this.studentName = val.userName;
      this.editDialogFlag = true;
    },
    //新增学生
    handleAddStudent() {
      if (this.notAllowOperate) return;
      this.addStudentDialogFlag = true;
    },

    //新增学生确认了 (可能要整个删除)
    addStudentConfirm(val) {
      addNewClassUser({
        classId: val.classId,
        userId: val.userId,
      }).then((res) => {
        if (res.result == 0) {
          this.$message({
            showClose: true,
            message: '新增学生成功',
            type: 'success',
          });
          this.init();
        }
      });
    },

    //转班确认了  (可能要整个删除)
    transferStudentConfirm(val) {
      this.loading = true;
      changeClassUser({
        oldClassId: val.oldClassId,
        userId: val.userId,
        newClassId: val.newClassId,
      })
        .then((res) => {
          if (res.result == 0) {
            this.$message({
              showClose: true,
              message: '学生转班成功',
              type: 'success',
            });
            this.init();
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    init() {
      this.loading = true;
      getClassUserList({
        classId: this.classId,
      })
        .then((res) => {
          this.gridData = res.result;
        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //修改学生内部学号
    updateInnerNoConfirm(val) {
      let mutipleNo = false;
      for (let i of this.gridData) {
        if (i.innerStudentNo == val.innerStudentNo && i.innerStudentNo) {
          mutipleNo = true;
          break;
        }
      }
      if (!mutipleNo) {
        this.loading = true;
        let arr = [];
        arr.push(val);
        updateClassUser({
          classUserJson: JSON.stringify(arr),
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: '编辑学生内部学号成功',
              type: 'success',
            });
            this.init();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      } else {
        this.$message({
          showClose: true,
          message: '学生班级内部学号重复',
          type: 'error',
        });
        this.init();
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dialog.name]: Dialog,
    transferClassDialog,
    addStudentDialog,
    // editInnerNoDialog,
  },
  watch: {
    dialogFlag() {
      this.dialogTableVisible = this.dialogFlag;
    },
    classId() {
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped src="../index.scss"></style>
