// methods
import { getClassList, getClassAdd, getTeacherStudentList } from '@/api/basic';

// components
import { Button, Table, TableColumn } from 'element-ui';
import basicHeader from '@/components/backStageHeader';
import qlPagination from '@/components/qlPagination';
import classDialog from './components/classDialog';
import studentListDialog from './components/studentListDialog';
//删除班级
import delClassDialog from './components/delClassDialog';

export default {
  name: 'class',
  props: {},
  data() {
    return {
      loading: false,
      listData: {
        list: [],
        pageSize: 10,
        pageIndex: 1,
        total: 0,
      },
      gradeList: [
        {
          gradeId: -1,
          gradeName: '全部',
        },
      ],
      selectedGradeId: -1,

      visible: false,
      formData: {
        index: -1,
        classId: null,
        className: '',
        gradeId: null,
        currentYear: '',
        teacherId: '',
      },
      isEdit: false,
      teacherOptions: [],

      // 学生名单
      dialogFlag: false,
      studentListData: [],
      classId: '',
      className: '',
      //删除班级
      delClassDialogFlag: false,
      message: '',
    };
  },
  components: {
    [Button.name]: Button,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    basicHeader,
    qlPagination,
    classDialog,
    studentListDialog,
    delClassDialog,
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
    studentList() {
      return this.studentListData;
    },
    notAllowOperate() {
      return this.userInfo.thirdpartySchoolProjectCode !== '';
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getClassList();
    },
    getClassList(reset = false) {
      let { pageIndex, pageSize } = this.listData;

      if (reset) {
        pageIndex = this.listData.pageIndex = 1;
      }

      this.loading = true;

      getClassList({
        pageIndex,
        pageSize,
        gradeId: this.selectedGradeId === -1 ? undefined : this.selectedGradeId,
      })
        .then(
          (res) => {
            let {
              classPage: { data, recordCount },
              gradeList,
            } = res.result;
            this.listData.total = recordCount;
            this.listData.list = data.filter((item) => item.gradeId == this.selectedGradeId);
            if (this.selectedGradeId === -1) {
              this.listData.list = data;
            }
            if (gradeList && gradeList.length > 0 && this.gradeList.length === 1) {
              let gradeLists = [...gradeList];
              gradeLists.sort((a, b) => {
                if (a.gradeId < b.gradeId) return -1;
                else return 1;
              });
              this.gradeList = [...this.gradeList, ...gradeLists];
            }
          },
          (err) => console.log(err)
        )
        .finally(() => {
          this.loading = false;
        });
    },

    handleSelect(idx) {
      const item = this.gradeList[idx];
      if (this.selectedGradeId !== item.gradeId) {
        this.selectedGradeId = item.gradeId;
        this.getClassList(true);
      }
    },

    // 新增班级
    handleAddClass() {
      if (this.notAllowOperate) return;

      this.isEdit = false;
      this.formData = {
        index: -1,
        classId: '',
        className: '',
        gradeId: this.selectedGradeId,
        teacherId: null,
      };
      this.getClassAdd(this.selectedGradeId);
    },

    // 编辑
    async handleEdit(val, idx) {
      if (this.notAllowOperate) return;

      this.isEdit = true;
      this.formData = {
        index: idx,
        classId: val.classId,
        className: val.className,
        gradeId: val.gradeId,
        currentYear: val.currentSchoolYear,
        teacherId: val.adviser && val.adviser.userId,
      };
      this.getClassAdd(val.gradeId);
    },

    async getClassAdd(id) {
      getClassAdd({
        gradeId: id,
      }).then((res) => {
        const { teacherList, year, grade } = res.result;
        this.teacherOptions = teacherList;
        this.formData.year = year;
        if (!this.isEdit) {
          this.formData.currentYear = grade?.currentSchoolYear;
        }
        this.visible = true;
      });
    },

    handleSuccess() {
      this.getClassList(this.isEdit ? false : true);
      this.visible = false;
    },

    handleSizeChange(val) {
      this.listData.pageSize = val;
      this.getClassList(true);
    },
    handleCurrentChange(val) {
      this.listData.pageIndex = val;
      this.getClassList();
    },

    //删除班级
    async handleDel(val) {
      if (this.notAllowOperate) return;

      const TeacherStudentList = await getTeacherStudentList({
        classId: val.classId,
      });

      this.classId = val.classId;
      const teacherAndStudent = TeacherStudentList.result;
      let teacherAndStudentName = '';
      for (let i in teacherAndStudent) {
        if (i < 2) {
          if (i == 1) {
            teacherAndStudentName += ',';
          }
          teacherAndStudentName += `${teacherAndStudent[i].userName}`;
        } else {
          teacherAndStudentName += '...';
          break;
        }
      }

      let mes = `该班下有"${teacherAndStudentName}"等${teacherAndStudent.length}人，确定删除"${val.className}"下的老师、学生吗？`;
      this.message = mes;
      this.delClassDialogFlag = true;
    },

    //查看班级名单
    async checkClassStudentList(val) {
      this.dialogFlag = true;
      this.className = val.className;
      this.classId = val.classId;
    },

    // 批量录入
    handleImport(type) {
      if (this.notAllowOperate) return;

      this.$batchEntry({
        type,
        callback: () => {
          this.selectedGradeId = -1;
          this.getClassList(true);
        },
      });
    },
    // 批量导出
    handleExport(type) {
      this.$batchExport({
        type,
      });
    },
    delClassConfirm() {
      this.$message({
        message: '删除成功',
        type: 'success',
      });
      this.init();
    },
  },
};
