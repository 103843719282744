<template>
  <div>
    <el-dialog
      custom-class="ql-dialog addStudentDialog"
      title="新增学生"
      :visible.sync="addStudentDialogVisible"
      @close="dialogClose"
      width="540px"
    >
      <el-form ref="form" :model="formData" label-width="56">
        <el-form-item label="新增学生" prop="newStudent" size="small">
          <el-select
            v-model="formData.userId"
            placeholder="请输入学生姓名"
            style="width: 372px"
            filterable
            @change="selectStudentChange"
            :filter-method="dataFilter"
          >
            <el-option
              v-for="item in studentOptions"
              :key="item.userId"
              :label="item.userName"
              :value="item.userId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleCancel" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="handleConfirm" :disabled="!formData.userId || disable"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { Button, Dialog, Form, FormItem, Select, Option } from 'element-ui';

//获取可用于新增的学生
import { selectNotInClassStudent } from '@/api/basic';

export default {
  name: 'AddStudentDialog',
  data() {
    return {
      addStudentDialogVisible: this.addStudentDialogFlag,
      formData: {
        userId: '',
      },
      studentOptions: [],
      disable: true,
    };
  },
  props: {
    addStudentDialogFlag: {
      type: Boolean,
    },
    classId: {
      type: String,
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  methods: {
    dialogClose() {
      this.formData.userId = '';
      this.studentOptions = [];
      this.$emit('update:addStudentDialogFlag', false);
    },
    handleCancel() {
      this.dialogClose();
    },
    handleConfirm() {
      // let userName = this.studentOptions.filter((item) => item.studentId == this.formData.studentId)[0].userName;
      this.$emit('addStudent', {
        userId: this.formData.userId,
        classId: this.classId,
      });
      this.dialogClose();
    },
    //
    selectStudentChange(val) {
      let student = this.studentOptions.filter((item) => item.userId == val)[0];
      if (student.classId == this.classId) {
        this.$message({
          message: '该学生已在班级内',
          type: 'warning',
          showClose: true,
        });
        this.disable = true;
      } else {
        this.disable = false;
      }
    },

    dataFilter(val) {
      if (val) {
        selectNotInClassStudent({
          classId: this.classId,
          studentUserName: val,
        }).then((res) => {
          this.studentOptions = res.result;
        });
      } else {
        this.studentOptions = [];
      }
    },
  },
  watch: {
    addStudentDialogFlag() {
      this.addStudentDialogVisible = this.addStudentDialogFlag;
    },
    classId() {
      this.studentOptions = [];
    },
  },
};
</script>
<style lang="scss" scoped></style>
