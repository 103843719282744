<template>
  <el-dialog
    custom-class="ql-dialog classDialog"
    :title="isEdit ? '编辑班级' : '新增班级'"
    :visible.sync="visible"
    width="578px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="83px">
      <el-form-item label="班级名称" prop="className" size="small">
        <el-input v-model="formData.className" :style="{ width: '400px' }"></el-input>
      </el-form-item>
      <el-form-item label="班主任" prop="teacherId" size="small">
        <el-select v-model="formData.teacherId" placeholder="请选择" :style="{ width: '400px' }" clearable>
          <el-option
            v-for="item in teacherOptions"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" size="small" @click="handleAdd">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Form, FormItem, Input, Select, Option } from 'element-ui';
import { addClass, updateClass } from '@/api/basic';

export default {
  name: 'ClassDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    isEdit: {
      type: Boolean,
      default: false,
    },
    teacherOptions: Array,
  },
  data() {
    const validateClassName = (rule, value, callback) => {
      if (!value) {
        callback(new Error('班级名称不能为空'));
        return;
      } else if (value.length > 25) {
        callback(new Error('班级名称不能超过25位'));
        return;
      }
      callback();
    };
    return {
      rules: {
        className: [
          {
            validator: validateClassName,
            trigger: 'blur',
            required: true,
          },
        ],
      },
      formData: {
        lessonId: null,
        subjectName: '',
      },
    };
  },
  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.formData = this.dialogData;
      }
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    handleAdd() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 新文档要求的
          if (!this.isEdit) {
            addClass({
              className: this.formData.className,
              gradeId: this.formData.gradeId,
              teacherId: this.formData.teacherId,
            })
              .then((res) => {
                if (res && res.result == 0) {
                  this.$message({
                    showClose: true,
                    message: '新增班级成功',
                    type: 'success',
                  });
                  this.$emit('success', this.formData);
                }
                // else {
                //   this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: 'error',
                //   });
                //   console.log('error')
                // }
              })
              .catch(() => {
                return;
              });
          } else {
            updateClass({
              className: this.formData.className,
              classId: this.formData.classId,
              teacherId: this.formData.teacherId,
            })
              .then((res) => {
                if (res && res.result == 0) {
                  this.$message({
                    showClose: true,
                    message: '编辑班级成功',
                    type: 'success',
                  });
                  this.$emit('success', this.formData);
                }
                // else {
                //   this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: 'error',
                //   });
                // }
              })
              .catch(() => {
                return;
              });
          }
          this.$emit('success', this.formData);
        }
        return false;
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.classDialog {
}
</style>
