<template>
  <div>
    <el-dialog
      custom-class="ql-dialog transferClassDialog"
      :title="dialogTitle"
      :visible.sync="transferDialogVisible"
      @close="dialogClose"
      width="540px"
    >
      <div class="transferTo">
        <span>转班至</span>
      </div>
      <el-form ref="form" :model="formData" label-width="44px">
        <el-form-item label="年级" prop="grade" size="small">
          <el-select
            v-model="formData.gradeId"
            placeholder="请选择年级"
            style="width: 400px"
            @change="selectGradeChange"
          >
            <el-option
              v-for="item in gradeOptions"
              :key="item.gradeId"
              :label="item.gradeName"
              :value="item.gradeId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级" prop="class" size="small">
          <el-select v-model="formData.classId" placeholder="请选择班级" style="width: 400px">
            <el-option
              v-for="item in classOptions"
              :key="item.classId"
              :label="item.className"
              :value="item.classId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="handleCancel" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="handleConfirm" :disabled="!formData.gradeId || !formData.classId"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getClassUserListData } from '@/api/basic';

import { Button, Dialog, Form, FormItem, Select, Option } from 'element-ui';

export default {
  name: 'TransferClassDialog',
  data() {
    return {
      transferDialogVisible: false,
      formData: {
        gradeId: '',
        classId: '',
      },
      classOptions: [],
    };
  },
  props: {
    transferDialogFlag: {
      type: Boolean,
    },
    gradeList: {
      type: Array,
    },
    studentName: {
      type: String,
    },
    userId: {
      type: String,
    },
    classId: {
      type: String,
    },
  },
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  methods: {
    dialogClose() {
      this.formData.gradeId = '';
      this.formData.classId = '';
      this.classOptions = [];
      this.$emit('update:transferDialogFlag', false);
    },
    handleCancel() {
      this.dialogClose();
    },
    handleConfirm() {
      let gradeName = this.gradeList.filter((item) => item.gradeId == this.formData.gradeId)[0].gradeName;
      let className = this.classOptions.filter((item) => item.classId == this.formData.classId)[0].className;
      let mes = `确定将该学生转入${gradeName}-${className}吗？转班后将保留学生在当前班级已产生的业务记录，但不再对业务记录进行统计分析`;
      this.$msgbox
        .confirm(mes, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })
        .then(() => {
          this.$emit('transferStudent', {
            oldClassId: this.classId,
            userId: this.userId,
            newClassId: this.formData.classId,
          });
          this.dialogClose();
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消转班',
          });
        });
    },
    selectGradeChange(val) {
      this.classOptions = [];
      this.formData.classId = '';

      getClassUserListData({
        gradeId: val,
        curUserId: this.userId,
      }).then((res) => {
        this.classOptions = res.result;
      });
    },
  },
  watch: {
    transferDialogFlag() {
      this.transferDialogVisible = this.transferDialogFlag;
    },
  },
  computed: {
    gradeOptions() {
      return this.gradeList
        .filter((item) => item.gradeId !== -1)
        .map((item) => {
          return {
            gradeId: item.gradeId,
            gradeName: item.gradeName,
          };
        });
    },
    dialogTitle() {
      return this.studentName + '-转班';
    },
  },
};
</script>
<style lang="scss" scoped>
.transferTo {
  margin-bottom: 15px;
  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
  }
}
</style>
