<template>
  <header class="backStageHeader flex justify-between items-center">
    <p class="backStageHeader-title" v-if="isShowTitle">
      {{ title }}<span v-if="subTitle" class="backStageHeader-subTitle">{{ subTitle }}</span>
    </p>
    <slot></slot>
  </header>
</template>

<script>
export default {
  name: 'BackStageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    isShowTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  components: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.backStageHeader {
  min-height: 68px;
  border-bottom: 1px solid #dedede;

  &-title {
    min-width: 80px;
    font-size: 18px;
    font-weight: 600;
    color: #303333;
  }

  &-subTitle {
    font-size: 12px;
    font-weight: 400;
    color: #808080;
    padding-left: 6px;
  }

  &::v-deep {
    .icon-tianjia {
      font-size: 12px;
      margin-right: 3px;
    }
    .el-button + .el-button {
      margin-left: 16px;
    }
  }
}
</style>
